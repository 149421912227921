import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@components/Grid'

const SettingsImage = ({ color, size }) => (
  <svg
    version="1.0"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 64 64"
    enableBackground="new 0 0 64 64"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={color}
        d="M60,23.997h-5.395c-0.283-0.797-0.605-1.57-0.965-2.326l3.812-3.812c1.562-1.565,1.562-4.098,0.002-5.658
		l-5.658-5.658c-1.561-1.561-4.094-1.561-5.656,0.002l-3.814,3.814C41.572,9.997,40.797,9.675,40,9.392V4.001
		c0-2.211-1.793-4.002-4-4.002h-8.001c-2.207,0-3.998,1.791-3.998,4.002v5.391c-0.797,0.283-1.572,0.605-2.326,0.967L17.86,6.544
		c-1.563-1.562-4.098-1.562-5.658-0.002L6.544,12.2c-1.561,1.561-1.561,4.093,0.002,5.656l3.814,3.814
		c-0.359,0.756-0.682,1.529-0.965,2.326H4.001c-2.209,0-4,1.791-4,4v7.999c0,2.209,1.791,4,4,4h5.395
		c0.283,0.797,0.605,1.57,0.967,2.326l-3.818,3.818c-1.562,1.561-1.562,4.098,0,5.656l5.656,5.66
		c1.562,1.559,4.095,1.559,5.656-0.002l3.818-3.818c0.756,0.359,1.529,0.682,2.326,0.965v5.396c-0.002,2.209,1.791,4.002,4,4L36,64
		c2.209-0.002,4-1.793,4-4v-5.398c0.797-0.283,1.57-0.605,2.326-0.965l3.816,3.816c1.561,1.562,4.096,1.562,5.656,0l5.658-5.656
		c1.561-1.562,1.561-4.096,0-5.656l-3.818-3.818c0.361-0.756,0.684-1.529,0.967-2.326H60c2.209,0,4-1.791,4-4v-7.999
		C64,25.788,62.209,23.997,60,23.997z M60,33.996c0,1.105-0.895,2-2,2h-6.402c-0.518,2.553-1.527,4.926-2.918,7.023l4.535,4.535
		c0.781,0.781,0.781,2.047,0,2.828l-2.83,2.828c-0.781,0.781-2.047,0.781-2.828,0l-4.533-4.533c-2.1,1.389-4.471,2.398-7.023,2.916
		V58c0,1.105-0.895,2-2,2h-3.999c-1.105-0.002-2-0.896-2-2v-6.406c-2.553-0.518-4.924-1.527-7.023-2.916l-4.536,4.535
		c-0.781,0.783-2.046,0.781-2.828,0l-2.828-2.828c-0.781-0.783-0.781-2.049,0-2.83l4.535-4.535c-1.391-2.098-2.4-4.471-2.918-7.023
		H6.001c-1.105,0-2-0.895-2-2v-3.999c0-1.105,0.895-2,2-2h6.402c0.518-2.553,1.527-4.924,2.916-7.023l-4.531-4.532
		c-0.783-0.781-0.781-2.046,0-2.828l2.83-2.828c0.781-0.781,2.045-0.783,2.828,0l4.532,4.531c2.098-1.391,4.471-2.4,7.023-2.918
		V6.001c-0.002-1.107,0.895-2,2-2H34c1.105,0,2,0.893,2,2v6.398c2.553,0.518,4.926,1.527,7.023,2.918l4.531-4.531
		c0.781-0.783,2.047-0.781,2.828,0.002l2.828,2.828c0.781,0.781,0.783,2.045,0,2.828l-4.529,4.53c1.389,2.1,2.398,4.471,2.916,7.023
		H58c1.105,0,2,0.895,2,2V33.996z"
      />
      <path
        fill={color}
        d="M32,19.997c-6.626,0-11.999,5.373-11.999,11.999c0,6.627,5.373,12,11.999,12c6.627,0,12-5.373,12-12
		C44,25.37,38.627,19.997,32,19.997z M32,39.996c-4.417,0-7.999-3.582-7.999-8c0-4.417,3.582-7.999,7.999-7.999
		c4.418,0,8,3.582,8,7.999C40,36.414,36.418,39.996,32,39.996z"
      />
    </g>
  </svg>
)

SettingsImage.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
}

const SettingsIcon = ({ color }) => (
  <Box
    sx={{
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    }}
  >
    <Box p={0} m={0}>
      <SettingsImage size="25px" color={color} />
    </Box>
  </Box>
)

SettingsIcon.propTypes = {
  color: PropTypes.string.isRequired,
}

export { SettingsIcon }
